<template>
  <BaseTooltip
    :placement="placement"
    :interactive="true"
    :offset-skid="filterOffsetX"
    :arrow="false"
    class="mm-dropdown-sort"
    @close:popper="isOpenPopup = false"
  >
    <div
      class="sort"
      :class="{ active: isOpenPopup, disabled }"
      @click="isOpenPopup = true"
    >
      <span class="sort__text">
        {{ text ?? modelValue?.name ?? placeholder }}
      </span>
      <SvgIcon
        :src="changeIcon"
        class="sort-icon"
      />
    </div>

    <template #content="{ close }">
      <div
        v-if="isOpenPopup"
        class="sort__popup"
      >
        <SortFilter
          :model-value="modelValue"
          :filter="filter"
          :need-search="needSearch"
          :radio-mode="radioMode"
          :show-button="showButton"
          :hide-name="hideName"
          :grouped="grouped"
          :disabled="isInteractionDisallowed"
          @update:model-value="onSelect($event, close)"
        />
      </div>
    </template>
  </BaseTooltip>
</template>

<script lang="ts" setup>
import { EPopperPlacement } from '../enums/popperPlacement.enum';
import { IFilterSort, IFilterSortOption } from '../models/filters.model';
import SortFilter from './filters/SortFilter.vue';

const props = withDefaults(
  defineProps<{
    filter?: IFilterSort;
    modelValue?: IFilterSortOption;
    svgIcon?: string;
    placeholder?: string;
    placement?: EPopperPlacement;
    isCloseAfterSelect?: boolean;
    disabled?: boolean;
    filterOffsetX?: string;
    needSearch?: boolean;
    radioMode?: boolean;
    showButton?: boolean;
    hideName?: boolean;
    grouped?: boolean;
    text?: string;
    /** Флаг, запрещающий взаимодействие с фильтром для сортировки */
    isInteractionDisallowed?: boolean;
  }>(),
  {
    showButton: false,
    isCloseAfterSelect: false,
    placement: EPopperPlacement.Bottom,
    isInteractionDisallowed: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', filter: IFilterSortOption): void;
  (e: 'update:singleValue', value: IFilterSortOption): void;
}>();

const isOpenPopup = ref(false);

const changeIcon = computed(() => {
  return isOpenPopup.value ? 'navigation/arrow-top-20px' : props.svgIcon || 'navigation/filter';
});

function onSelect(event: IFilterSortOption, close: () => void): void {
  emit('update:modelValue', event);
  props.isCloseAfterSelect && close();
}
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

:global(.mm-dropdown-sort > .popper) {
  background-color: transparent !important;
}

.sort {
  border: 1px solid $filter-br;
  border-radius: 6px;
  padding: 8px 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: max-content;

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  &.active {
    background: $filter-bc;
  }

  &__text {
    color: $filter-text-c;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px;
  }

  &__popup {
    background: $filter-popup-bc;
    border-radius: 8px;
    box-shadow: 0 2px 18px rgb(0 0 0 / 12%);
    margin-top: 4px;

    .mm-filter-name {
      font-size: 16px;
      line-height: 24px;
      color: $text-black;
      font-weight: 500;
    }
  }

  :deep(svg) {
    width: 20px;
    height: 20px;
  }
}

:deep(.mm-input) {
  padding-bottom: 0;
}
</style>
